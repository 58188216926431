




























import { Component } from 'vue-property-decorator'

import { Heading } from '../../../../contracts'

import { FormFieldset } from '../../../atoms'
import { HeadingForm } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { searchResultsContentFactory } from '../SearchResults.factory'
import { SearchResultsModule, SearchResultsModuleContent } from '../SearchResults.contracts'
import { MarkdownEditor } from '../../../molecules/MarkdownEditor'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'SearchResultsModuleForm',
  components: {
    FormFieldset,
    HeadingForm,
    MarkdownEditor
  }
})
export class SearchResultsModuleForm extends AbstractModuleForm<SearchResultsModule> {
  protected initialContent: SearchResultsModuleContent = searchResultsContentFactory()

  /**
   * Heading of no results section
   */
  public get heading (): Heading {
    if (typeof this._content.noResultsInfo === 'undefined') {
      this._content.noResultsInfo = {
        heading: {
          level: 1,
          text: ''
        }
      }

      return typeof this._content.noResultsInfo.heading === 'undefined' ? { level: 1, text: '' } : this._content.noResultsInfo.heading
    } else {
      if (typeof this._content.noResultsInfo.heading !== 'undefined') {
        return this._content.noResultsInfo.heading
      }

      return { level: 1, text: '' }
    }
  }

  public set heading (heading: Heading) {
    if (typeof this._content.noResultsInfo === 'undefined') {
      this._content.noResultsInfo = {
        heading: {
          text: !Object.prototype.hasOwnProperty.call(heading, 'text') ? '' : heading.text,
          level: heading.level
        }
      }
    } else {
      this._content.noResultsInfo.heading = {
        level: heading.level,
        text: !Object.prototype.hasOwnProperty.call(heading, 'text') ? '' : heading.text
      }
    }
  }

  /**
   * Leading of no results section
   */
  public get intro (): string {
    if (typeof this._content.noResultsInfo === 'undefined') {
      this._content.noResultsInfo = {
        intro: ''
      }

      return typeof this._content.noResultsInfo.intro === 'undefined' ? '' : this._content.noResultsInfo.intro
    }
    if (typeof this._content.noResultsInfo !== 'undefined' &&
      typeof this._content.noResultsInfo?.intro !== 'undefined') {
      return this._content.noResultsInfo.intro
    } else {
      return ''
    }
  }

  public set intro (text: string) {
    if (typeof this._content.noResultsInfo === 'undefined') {
      this._content.noResultsInfo = {
        intro: text
      }
    } else {
      this._content.noResultsInfo.intro = text
    }
  }
}
export default SearchResultsModuleForm
